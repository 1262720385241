@import '../node_modules/font-awesome/css/font-awesome.css';
@import '../node_modules/loaders.css/loaders.css';
@import '../node_modules/simple-line-icons/css/simple-line-icons.css';
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

.ng-valid[required], .ng-valid.ng-dirty.required  {
  border-left: 3px solid #42A948; /* green */
}

.ng-invalid.ng-dirty:not(form)  {
  border-left: 3px solid #a94442; /* red */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ngui-datetime-picker {
  font-size: 12px !important;
}

.ngui-datetime-picker .days .day-of-week,
.ngui-datetime-picker .days .day {
  width: 27px !important;
}
