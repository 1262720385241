/* ========================================================================
   Component: preloader
 ========================================================================== */
@import "../../shared/styles/app/variables";
@import "../../shared/styles/bootstrap/bootstrap/_mixins";

.preloader{ position:fixed; left:0; right:0; top:0; bottom:0; z-index:99999; text-align:center; background:$brand-primary; @include transition(opacity .65s);
  .icon-holder{ width:100px; height:100px; position:absolute; left:0; right:0; top:0; bottom:0; margin:auto; padding:10px;}
  .white-container-box{ box-shadow:0 0 6px 0 #f1f1f1; border-radius:5px; background:#FFF;}
  img{ height:100%;}
  .preloader-progress.icon-holder img{ display:inline-block!important;}
}
// ngAnimate behavior
.preloader-hidden-add{ opacity:1; display:block;
  .preloader-progress{ @include transition-transform(.4s ease); @include scale(0);}
}
.preloader-hidden-add-active{ opacity:0;}
.preloader-progress-bar{ display:none;}
.preloader-hidden{ display:none;}
